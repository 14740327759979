"use strict";
import { useCurrencyInfo } from "hooks/Tokens";
import { useMemo } from "react";
import { TokenList } from "uniswap/src/features/dataApi/types";
export function useIsSwapUnsupported(currencyIn, currencyOut) {
  const currencyInInfo = useCurrencyInfo(currencyIn);
  const currencyOutInfo = useCurrencyInfo(currencyOut);
  return useMemo(() => {
    return currencyInInfo?.safetyInfo?.tokenList === TokenList.Blocked || currencyOutInfo?.safetyInfo?.tokenList === TokenList.Blocked;
  }, [currencyInInfo?.safetyInfo?.tokenList, currencyOutInfo?.safetyInfo?.tokenList]);
}
