"use strict";
import { CurrencyRow } from "components/SearchModal//CurrencyList";
import { CurrencySearch } from "components/SearchModal/CurrencySearch";
import { memo } from "react";
import styled from "lib/styled-components";
import { TOKEN_SELECTOR_WEB_MAX_WIDTH } from "uniswap/src/components/TokenSelector/TokenSelector";
import { Modal } from "uniswap/src/components/modals/Modal";
import { ModalName } from "uniswap/src/features/telemetry/constants";
import { CurrencyField } from "uniswap/src/types/currency";
const PoolListWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
`;
const PoolListContainer = styled.div`
  width: 100%;
  padding: 32px 25px;
  display: flex;
  flex-flow: column;
  align-items: left;
`;
export default memo(function CurrencySearchModal({
  isOpen,
  onDismiss,
  onCurrencySelect,
  currencyField = CurrencyField.INPUT,
  chainIds,
  operatedPools,
  shouldDisplayPoolsOnly
}) {
  return <Modal
    isModalOpen={isOpen}
    onClose={onDismiss}
    maxHeight={700}
    height="100vh"
    maxWidth={TOKEN_SELECTOR_WEB_MAX_WIDTH}
    padding={0}
    flex={1}
    name={ModalName.CurrencySearch}
  >{!shouldDisplayPoolsOnly && <CurrencySearch
    currencyField={currencyField}
    onCurrencySelect={onCurrencySelect}
    onDismiss={onDismiss}
    chainIds={chainIds}
  />} : <PoolListWrapper><PoolListContainer>{operatedPools?.map((pool) => <CurrencyRow
    key={pool.currencyId}
    currencyInfo={pool}
    onSelect={() => {
      onCurrencySelect(pool.currency);
      onDismiss();
    }}
    isSelected={false}
    balance={void 0}
    isSmartPool={true}
    eventProperties={{}}
    otherSelected={false}
  />)}</PoolListContainer></PoolListWrapper></Modal>;
});
