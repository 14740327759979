"use strict";
import {
  TransactionType
} from "state/transactions/types";
import { getNativeAddress } from "uniswap/src/constants/addresses";
import { AssetType } from "uniswap/src/entities/assets";
import {
  TransactionType as UniswapTransactionType
} from "uniswap/src/features/transactions/types/transactionDetails";
import { buildCurrencyId, buildNativeCurrencyId } from "uniswap/src/utils/currencyId";
const createUniverseSwapTransaction = (info, chainId) => {
  const inputCurrencyId = info.inputCurrencyId === "ETH" ? null : buildCurrencyId(chainId, info.inputCurrencyId);
  const outputCurrencyId = info.outputCurrencyId === "ETH" ? null : buildCurrencyId(chainId, info.outputCurrencyId);
  const nativeCurrencyId = buildNativeCurrencyId(chainId);
  return {
    typeInfo: {
      type: UniswapTransactionType.Swap,
      inputCurrencyId: inputCurrencyId ?? nativeCurrencyId,
      outputCurrencyId: outputCurrencyId ?? nativeCurrencyId
    }
  };
};
const createUniverseBridgeTransaction = (info, inputChainId, outputChainId) => {
  const inputCurrencyId = info.inputCurrencyId === "ETH" ? null : buildCurrencyId(inputChainId, info.inputCurrencyId);
  const outputCurrencyId = info.outputCurrencyId === "ETH" ? null : buildCurrencyId(outputChainId, info.outputCurrencyId);
  const inputNativeCurrencyId = buildNativeCurrencyId(inputChainId);
  const outputNativeCurrencyId = buildNativeCurrencyId(outputChainId);
  return {
    typeInfo: {
      type: UniswapTransactionType.Bridge,
      inputCurrencyId: inputCurrencyId ?? inputNativeCurrencyId,
      outputCurrencyId: outputCurrencyId ?? outputNativeCurrencyId,
      inputCurrencyAmountRaw: info.inputCurrencyAmountRaw,
      outputCurrencyAmountRaw: info.outputCurrencyAmountRaw
    }
  };
};
const createUniverseSendTransaction = (info, chainId) => {
  return {
    typeInfo: {
      type: UniswapTransactionType.Send,
      tokenAddress: info.currencyId === "ETH" ? getNativeAddress(chainId) : info.currencyId,
      assetType: AssetType.Currency,
      recipient: info.recipient
    }
  };
};
const createUniverseWrapTransaction = (info) => {
  return {
    typeInfo: {
      type: UniswapTransactionType.Wrap,
      unwrapped: info.unwrapped,
      currencyAmountRaw: info.currencyAmountRaw
    }
  };
};
export const createUniverseTransaction = (info, chainId, address) => {
  const baseTransaction = {
    chainId,
    from: address
  };
  let transaction;
  switch (info.type) {
    case TransactionType.SWAP:
      transaction = createUniverseSwapTransaction(info, chainId);
      break;
    case TransactionType.BRIDGE:
      transaction = createUniverseBridgeTransaction(info, info.inputChainId, info.outputChainId);
      break;
    case TransactionType.SEND:
      transaction = createUniverseSendTransaction(info, chainId);
      break;
    case TransactionType.WRAP:
      transaction = createUniverseWrapTransaction(info);
      break;
    case TransactionType.CREATE_POSITION:
    case TransactionType.INCREASE_LIQUIDITY:
    case TransactionType.DECREASE_LIQUIDITY:
    case TransactionType.MIGRATE_LIQUIDITY_V3_TO_V4:
    case TransactionType.COLLECT_FEES:
      transaction = createUniverseSwapTransaction(
        {
          inputCurrencyId: info.token0CurrencyId,
          outputCurrencyId: info.token1CurrencyId
        },
        chainId
      );
      break;
    case TransactionType.MIGRATE_LIQUIDITY_V2_TO_V3:
      transaction = createUniverseSwapTransaction(
        {
          inputCurrencyId: info.baseCurrencyId,
          outputCurrencyId: info.quoteCurrencyId
        },
        chainId
      );
      break;
    case TransactionType.APPROVAL:
    case TransactionType.DEPOSIT_LIQUIDITY_STAKING:
    case TransactionType.WITHDRAW_LIQUIDITY_STAKING:
    case TransactionType.CLAIM:
    case TransactionType.VOTE:
    case TransactionType.DELEGATE:
    case TransactionType.CREATE_V3_POOL:
    case TransactionType.ADD_LIQUIDITY_V3_POOL:
    case TransactionType.REMOVE_LIQUIDITY_V3:
    case TransactionType.ADD_LIQUIDITY_V2_POOL:
    case TransactionType.SUBMIT_PROPOSAL:
    case TransactionType.QUEUE:
    case TransactionType.EXECUTE:
    case TransactionType.BUY:
    case TransactionType.SELL:
    case TransactionType.SET_SPREAD:
    case TransactionType.SET_LOCKUP:
    case TransactionType.SET_VALUE:
      return { ...baseTransaction, ...info };
    default:
      assertUnreachable(info);
  }
  return { ...baseTransaction, ...transaction };
};
function assertUnreachable(x) {
  throw new Error("Unhandled case: " + x);
}
