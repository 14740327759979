"use strict";
import { LiquidityEventName } from "@uniswap/analytics-events";
import { getLiquidityEventName } from "components/Liquidity/analytics";
import { PopupType, addPopup } from "state/application/reducer";
import {
  handleApprovalTransactionStep,
  handleOnChainStep
} from "state/sagas/transactions/utils";
import {
  TransactionType
} from "state/transactions/types";
import invariant from "tiny-invariant";
import { call, put } from "typed-redux-saga";
import { sendAnalyticsEvent } from "uniswap/src/features/telemetry/send";
import {
  LiquidityTransactionType
} from "uniswap/src/features/transactions/liquidity/types";
import {
  TransactionStepType
} from "uniswap/src/features/transactions/swap/types/steps";
import { generateTransactionSteps } from "uniswap/src/features/transactions/swap/utils/generateTransactionSteps";
import { createSaga } from "uniswap/src/utils/saga";
import { logger } from "utilities/src/logger/logger";
import { currencyId } from "utils/currencyId";
function* getLiquidityTxRequest(step, signature) {
  if (step.type === TransactionStepType.IncreasePositionTransaction || step.type === TransactionStepType.DecreasePositionTransaction || step.type === TransactionStepType.MigratePositionTransactionStep || step.type === TransactionStepType.CollectFeesTransactionStep) {
    return step.txRequest;
  }
  if (!signature) {
    throw new Error("Signature required for async increase position transaction step");
  }
  const txRequest = yield* call(step.getTxRequest, signature);
  invariant(txRequest !== void 0, "txRequest must be defined");
  return txRequest;
}
function* handlePositionTransactionStep(params) {
  const { action, step, signature, analytics } = params;
  const info = getLiquidityTransactionInfo(action);
  const txRequest = yield* call(getLiquidityTxRequest, step, signature);
  const onModification = ({ hash: hash2, data }) => {
    if (analytics) {
      sendAnalyticsEvent(LiquidityEventName.TRANSACTION_MODIFIED_IN_WALLET, {
        ...analytics,
        transaction_hash: hash2,
        expected: txRequest.data?.toString(),
        actual: data
      });
    }
  };
  const onChainStep = { ...step, txRequest };
  const hash = yield* call(handleOnChainStep, {
    ...params,
    info,
    step: onChainStep,
    shouldWaitForConfirmation: false,
    onModification
  });
  if (analytics) {
    sendAnalyticsEvent(getLiquidityEventName(onChainStep.type), {
      ...analytics,
      transaction_hash: hash
    });
  }
  yield* put(addPopup({ content: { type: PopupType.Transaction, hash }, key: hash }));
}
function* modifyLiquidity(params) {
  const {
    account,
    setCurrentStep,
    steps,
    liquidityTxContext: { action },
    onSuccess,
    onFailure,
    analytics
  } = params;
  let signature;
  try {
    for (const step of steps) {
      switch (step.type) {
        case TransactionStepType.TokenRevocationTransaction:
        case TransactionStepType.TokenApprovalTransaction: {
          yield* call(handleApprovalTransactionStep, { account, step, setCurrentStep });
          break;
        }
        case TransactionStepType.Permit2Signature: {
          signature = void 0;
          break;
        }
        case TransactionStepType.IncreasePositionTransaction:
        case TransactionStepType.IncreasePositionTransactionAsync:
        case TransactionStepType.DecreasePositionTransaction:
        case TransactionStepType.MigratePositionTransactionStep:
        case TransactionStepType.MigratePositionTransactionStepAsync:
        case TransactionStepType.CollectFeesTransactionStep:
          yield* call(handlePositionTransactionStep, { account, step, setCurrentStep, action, signature, analytics });
          break;
        default: {
          throw new Error("Unexpected step type");
        }
      }
    }
  } catch (e) {
    const cause = e instanceof Error && e.cause;
    logger.error(e, { tags: { file: "liquiditySaga", function: "modifyLiquidity" }, extra: { tradingApiError: cause } });
    onFailure();
    return;
  }
  yield* call(onSuccess);
}
function* liquidity(params) {
  const { liquidityTxContext, startChainId, selectChain, onFailure } = params;
  const steps = yield* call(generateTransactionSteps, liquidityTxContext);
  params.setSteps(steps);
  const token0ChainId = liquidityTxContext.action.currency0Amount.currency.chainId;
  const token1ChainId = liquidityTxContext.action.currency1Amount.currency.chainId;
  if (token0ChainId !== token1ChainId) {
    logger.error("Tokens must be on the same chain", {
      tags: { file: "liquiditySaga", function: "liquidity" }
    });
    onFailure();
    return void 0;
  }
  if (token0ChainId !== startChainId) {
    const chainSwitched = yield* call(selectChain, token0ChainId);
    if (!chainSwitched) {
      onFailure();
      return void 0;
    }
  }
  return yield* modifyLiquidity({
    ...params,
    steps
  });
}
export const liquiditySaga = createSaga(liquidity, "liquiditySaga");
function getLiquidityTransactionInfo(action) {
  let type;
  switch (action.type) {
    case LiquidityTransactionType.Create:
      type = TransactionType.CREATE_POSITION;
      break;
    case LiquidityTransactionType.Increase:
      type = TransactionType.INCREASE_LIQUIDITY;
      break;
    case LiquidityTransactionType.Decrease:
      type = TransactionType.DECREASE_LIQUIDITY;
      break;
    case LiquidityTransactionType.Migrate:
      type = TransactionType.MIGRATE_LIQUIDITY_V3_TO_V4;
      break;
    case LiquidityTransactionType.Collect:
      type = TransactionType.COLLECT_FEES;
  }
  const {
    currency0Amount: { currency: currency0, quotient: quotient0 },
    currency1Amount: { currency: currency1, quotient: quotient1 }
  } = action;
  return {
    type,
    token0CurrencyId: currencyId(currency0),
    token1CurrencyId: currencyId(currency1),
    token0CurrencyAmountRaw: quotient0.toString(),
    token1CurrencyAmountRaw: quotient1.toString()
  };
}
