"use strict";
import { Interface } from "@ethersproject/abi";
import { RowFixed } from "components/deprecated/Row";
import PoolPositionListItem from "components/PoolPositionListItem";
import { MouseoverTooltip } from "components/Tooltip";
import { useAccount } from "hooks/useAccount";
import { Trans } from "react-i18next";
import { useMultipleContractSingleData } from "lib/hooks/multicall";
import { useMemo } from "react";
import { Info } from "react-feather";
import { useStakingPoolsRewards } from "state/pool/hooks";
import styled from "lib/styled-components";
import { MEDIA_WIDTHS } from "theme";
import POOL_EXTENDED_ABI from "uniswap/src/abis/pool-extended.json";
const DesktopHeader = styled.div`
  display: none;
  font-size: 14px;
  font-weight: 500;
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.surface3};

  @media screen and (min-width: ${MEDIA_WIDTHS.deprecated_upToSmall}px) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    & > div:last-child {
      text-align: right;
      margin-right: 12px;
    }
  }
`;
const MobileHeader = styled.div`
  font-weight: medium;
  padding: 8px;
  font-weight: 500;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.surface3};

  @media screen and (min-width: ${MEDIA_WIDTHS.deprecated_upToSmall}px) {
    display: none;
  }

  @media screen and (max-width: ${MEDIA_WIDTHS.deprecated_upToExtraSmall}px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;
const InfoIconContainer = styled.div`
  margin-left: 2px;
  display: flex;
  align-items: center;
  cursor: help;
`;
export default function PoolPositionList({ positions, filterByOperator }) {
  const account = useAccount();
  const poolAddresses = useMemo(() => positions.map((p) => p.pool), [positions]);
  const poolIds = useMemo(() => positions.map((p) => p.id), [positions]);
  const PoolInterface = new Interface(POOL_EXTENDED_ABI);
  const poolsRewards = useStakingPoolsRewards(poolIds);
  const userBalances = useMultipleContractSingleData(
    poolAddresses,
    PoolInterface,
    "balanceOf",
    useMemo(() => [account.address], [account.address])
  );
  const results = useMultipleContractSingleData(poolAddresses, PoolInterface, "getPool");
  const poolsWithStats = useMemo(() => {
    return results?.map((result, i) => {
      const { result: pool, loading } = result;
      if (!account.chainId || loading || !pool) {
        return void 0;
      }
      const { decimals, owner } = pool[0];
      if (!decimals || !owner) {
        return void 0;
      }
      const shouldDisplay = filterByOperator ? Boolean(owner === account.address || Number(userBalances?.[i]?.result) > 0) : true;
      return {
        ...result,
        apr: positions?.[i]?.apr,
        irr: positions?.[i]?.irr,
        poolOwnStake: positions?.[i]?.poolOwnStake,
        poolDelegatedStake: positions?.[i]?.poolDelegatedStake,
        userHasStake: positions?.[i]?.userHasStake ?? false,
        address: poolAddresses[i],
        decimals,
        symbol: positions?.[i]?.symbol,
        name: positions?.[i]?.name,
        chainId: account.chainId,
        shouldDisplay,
        userIsOwner: account.address ? owner === account.address : false,
        userBalance: userBalances?.[i]?.result,
        id: poolIds[i],
        currentEpochReward: poolsRewards[i]
      };
    }).filter((p) => p && p.shouldDisplay);
  }, [account.address, account.chainId, filterByOperator, poolAddresses, positions, results, userBalances, poolIds, poolsRewards]);
  return <><DesktopHeader><div>{filterByOperator ? <Trans>Your pools</Trans> : <Trans>Loaded pools</Trans>}{positions && " (" + poolsWithStats.length + ")"}</div>{filterByOperator && <RowFixed gap="32px"><RowFixed gap="2px"><Trans>Points</Trans><MouseoverTooltip
    text={<Trans>
                    The bigger the pool&apos;s own stake, the higher the points. Together with the other stakers&apos;
                    stake, they compete for a share of the current epoch&apos;s rewards.
                  </Trans>}
    placement="right"
  ><InfoIconContainer><Info size={14} /></InfoIconContainer></MouseoverTooltip></RowFixed></RowFixed>}{!filterByOperator && <RowFixed gap="32px"><RowFixed gap="2px"><Trans>IRR</Trans><MouseoverTooltip
    text={<Trans>
                    The pool operator&apos;s annualized yield. Increases as more stakers join the pool. Decreases as the
                    pool operator shares more of his revenue.
                  </Trans>}
    placement="right"
  ><InfoIconContainer><Info size={14} /></InfoIconContainer></MouseoverTooltip></RowFixed><RowFixed gap="2px"><Trans>APR</Trans><MouseoverTooltip
    text={<Trans>
                    The stakers&apos; annualized yield. Increases as the pool increases its own stake or as the pool
                    operator increases the percent of rewards shared.
                  </Trans>}
    placement="right"
  ><InfoIconContainer><Info size={14} /></InfoIconContainer></MouseoverTooltip></RowFixed></RowFixed>}</DesktopHeader><MobileHeader><div>{filterByOperator ? <Trans>Your pools</Trans> : <Trans>Loaded pools</Trans>}</div>{!filterByOperator ? <RowFixed style={{ gap: "40px", marginRight: "8px" }}><div><Trans>IRR</Trans></div><div><Trans>APR</Trans></div></RowFixed> : <RowFixed style={{ gap: "40px", marginRight: "8px" }}><div><Trans>Points</Trans></div></RowFixed>}</MobileHeader>{poolsWithStats.length > 0 ? poolsWithStats.map((p) => {
    return <PoolPositionListItem
      key={p?.address.toString()}
      positionDetails={p}
      returnPage={filterByOperator ? "mint" : "stake"}
    />;
  }) : !filterByOperator && !account.isConnected ? <><DesktopHeader><div><Trans>Could not retrieve pools. Try again by connecting your wallet.</Trans></div></DesktopHeader><MobileHeader><Trans>Could not retrieve pools. Try again by connecting your wallet.</Trans></MobileHeader></> : filterByOperator && account.isConnected ? <><DesktopHeader><div><Trans>You don&apos;t have a smart pool. Create yours or buy an existing one.</Trans></div></DesktopHeader><MobileHeader><Trans>You don&apos;t have a smart pool. Create yours or buy an existing one.</Trans></MobileHeader></> : <><DesktopHeader><div><Trans>Could not retrieve pools. RPC endpoint is down.</Trans></div></DesktopHeader><MobileHeader><Trans>Could not retrieve pools. RPC endpoint is down.</Trans></MobileHeader></>}</>;
}
