"use strict";
import { ClickableTamaguiStyle } from "theme/components";
import { Anchor, Flex, Main, Text, styled } from "ui/src";
import { Arrow } from "ui/src/components/arrow/Arrow";
import { fonts, iconSizes } from "ui/src/theme";
const LOADER_PADDING = 2;
export function TextLoader({ variant, width }) {
  const height = fonts[variant].lineHeight;
  return <Flex
    backgroundColor="$surface3"
    borderRadius="$rounded6"
    width={width}
    height={height - LOADER_PADDING * 2}
    my={LOADER_PADDING}
  />;
}
export const LoadingRow = styled(Flex, {
  my: "$spacing16"
});
export function ExternalArrowLink({
  href,
  openInNewTab = true,
  children
}) {
  return <Anchor
    textDecorationLine="none"
    href={href}
    target={openInNewTab ? "_blank" : void 0}
    {...ClickableTamaguiStyle}
  ><Flex gap="$gap8" alignItems="center" row><Text variant="buttonLabel3" color="$neutral2">{children}</Text><Arrow size={iconSizes.icon20} color="$neutral2" /></Flex></Anchor>;
}
export const BodyWrapper = styled(Main, {
  backgroundColor: "$surface1",
  display: "flex",
  flexDirection: "column",
  gap: "$spacing32",
  width: "100%",
  maxWidth: 1200,
  zIndex: "$default",
  py: "$spacing24",
  px: "$spacing40",
  $lg: {
    px: "$padding20"
  }
});
export const HeaderButton = styled(Flex, {
  row: true,
  backgroundColor: "$surface2",
  borderRadius: "$rounded12",
  alignItems: "center",
  justifyContent: "center",
  gap: "$gap4",
  py: "$padding8",
  px: "$padding12",
  ...ClickableTamaguiStyle,
  variants: {
    emphasis: {
      primary: {
        backgroundColor: "$accent3"
      },
      secondary: {
        backgroundColor: "$surface2"
      }
    },
    disabled: {
      true: {
        cursor: "default",
        hoverStyle: {
          opacity: 0.5
        },
        pressStyle: {
          opacity: 0.5
        }
      }
    }
  }
});
