"use strict";
import { Interface } from "@ethersproject/abi";
import { isAddress } from "@ethersproject/address";
import { BigNumber } from "@ethersproject/bignumber";
import { parseBytes32String } from "@ethersproject/strings";
import { Token } from "@uniswap/sdk-core";
import { useWeb3React } from "@web3-react/core";
import { RB_FACTORY_ADDRESSES, RB_REGISTRY_ADDRESSES } from "constants/addresses";
import { POOLS_LIST } from "constants/lists";
import { ZERO_ADDRESS } from "constants/misc";
import { useAccount } from "hooks/useAccount";
import { useContract } from "hooks/useContract";
import usePrevious from "hooks/usePrevious";
import { useTotalSupply } from "hooks/useTotalSupply";
import { useMultipleContractSingleData, useSingleContractMultipleData } from "lib/hooks/multicall";
import useBlockNumber from "lib/hooks/useBlockNumber";
import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useSelectActiveSmartPool } from "state/application/hooks";
import { useStakingContract } from "state/governance/hooks";
import { usePoolsFromUrl } from "state/lists/poolsList/hooks";
import { useLogs } from "state/logs/hooks";
import { useTransactionAdder } from "state/transactions/hooks";
import { TransactionType } from "state/transactions/types";
import POOL_EXTENDED_ABI from "uniswap/src/abis/pool-extended.json";
import RB_POOL_FACTORY_ABI from "uniswap/src/abis/rb-pool-factory.json";
import RB_REGISTRY_ABI from "uniswap/src/abis/rb-registry.json";
import { GRG } from "uniswap/src/constants/tokens";
import { UniverseChainId } from "uniswap/src/features/chains/types";
import { calculateGasMargin } from "utils/calculateGasMargin";
const RegistryInterface = new Interface(RB_REGISTRY_ABI);
export function useRegistryContract() {
  const account = useAccount();
  return useContract(
    account.chainId ? RB_REGISTRY_ADDRESSES[account.chainId] : void 0,
    RB_REGISTRY_ABI,
    true
  );
}
function usePoolFactoryContract() {
  const account = useAccount();
  return useContract(
    account.chainId ? RB_FACTORY_ADDRESSES[account.chainId] : void 0,
    RB_POOL_FACTORY_ABI,
    true
  );
}
export function usePoolExtendedContract(poolAddress) {
  return useContract(poolAddress, POOL_EXTENDED_ABI, true);
}
function useStartBlock(chainId) {
  let registryStartBlock;
  const blockNumber = useBlockNumber();
  const toBlock = chainId === 56 ? blockNumber : void 0;
  if (chainId === UniverseChainId.Mainnet) {
    registryStartBlock = 15834693;
  } else if (chainId === UniverseChainId.Sepolia) {
    registryStartBlock = 7707806;
  } else if (chainId === UniverseChainId.ArbitrumOne) {
    registryStartBlock = 35439804;
  } else if (chainId === UniverseChainId.Optimism) {
    registryStartBlock = 34629059;
  } else if (chainId === UniverseChainId.Polygon) {
    registryStartBlock = 35228892;
  } else if (chainId === UniverseChainId.Base) {
    registryStartBlock = 2565256;
  } else if (chainId === UniverseChainId.Bnb) {
    registryStartBlock = 25549625;
  } else {
    registryStartBlock = 1e3;
  }
  return { fromBlock: registryStartBlock, toBlock };
}
export function useAllPoolsData() {
  const account = useAccount();
  const registry = useRegistryContract();
  const formattedLogsV1 = useRegisteredPools();
  const poolsFromList = usePoolsFromList(registry, account.chainId);
  return useMemo(() => {
    const pools = [...formattedLogsV1 ?? [], ...poolsFromList ?? []];
    const uniquePools = pools.filter((obj, index) => {
      return index === pools.findIndex((o) => obj.pool === o.pool);
    });
    if (registry && !formattedLogsV1 && !poolsFromList) {
      return { data: [], loading: true };
    }
    return { data: uniquePools, loading: false };
  }, [formattedLogsV1, registry, poolsFromList]);
}
export function usePoolsFromList(regitry, chainId) {
  const poolsFromList = usePoolsFromUrl(POOLS_LIST);
  const pools = useMemo(
    () => poolsFromList?.filter((n) => n?.chainId === chainId),
    [chainId, poolsFromList]
  );
  const poolAddresses = useMemo(() => pools?.map((p) => [p.address]), [pools]);
  const result = useSingleContractMultipleData(regitry, "getPoolIdFromAddress", poolAddresses ?? []);
  return useMemo(() => {
    const poolIds = result.map((call) => {
      const result2 = call?.result;
      return result2?.[0];
    });
    return pools?.map((p, i) => {
      const pool = p.address;
      const name = p.name;
      const symbol = p.symbol;
      const id = poolIds[i];
      return { pool, name, symbol, id };
    });
  }, [
    pools,
    /*poolsLoading, poolsError,*/
    result
  ]);
}
export function useCreateCallback() {
  const account = useAccount();
  const { provider } = useWeb3React();
  const addTransaction = useTransactionAdder();
  const factoryContract = usePoolFactoryContract();
  return useCallback(
    (name, symbol, currencyValue) => {
      const parsedAddress = currencyValue?.isNative ? ZERO_ADDRESS : currencyValue?.address;
      if (!provider || !account.chainId || !account.address || !name || !symbol || !parsedAddress || !isAddress(parsedAddress ?? "")) {
        return void 0;
      }
      if (currencyValue?.chainId !== account.chainId) {
        throw new Error("User Switched Wallet On Open Create Modal");
      }
      if (!factoryContract) {
        throw new Error("No Factory Contract!");
      }
      return factoryContract.estimateGas.createPool(name, symbol, parsedAddress, {}).then((estimatedGasLimit) => {
        return factoryContract.createPool(name, symbol, parsedAddress, { value: null, gasLimit: calculateGasMargin(estimatedGasLimit) }).then((response) => {
          addTransaction(response, {
            type: TransactionType.CREATE_V3_POOL
          });
          return response.hash;
        });
      });
    },
    [account.address, addTransaction, account.chainId, provider, factoryContract]
  );
}
function useRegisteredPools() {
  const account = useAccount();
  const registry = useRegistryContract();
  const { fromBlock, toBlock } = useStartBlock(account.chainId);
  const filter = useMemo(() => {
    const filter2 = registry?.filters?.Registered();
    if (!filter2) {
      return void 0;
    }
    return {
      ...filter2,
      fromBlock,
      toBlock
    };
  }, [registry, fromBlock, toBlock]);
  const logsResult = useLogs(filter);
  return logsResult?.logs?.map((log) => {
    const parsed = RegistryInterface.parseLog(log).args;
    return parsed;
  })?.map((parsed) => {
    const group = parsed.group;
    const pool = parsed.pool;
    const name = parseBytes32String(parsed.name);
    const symbol = parseBytes32String(parsed.symbol);
    const id = parsed.id;
    const poolData = { group, pool, name, symbol, id };
    return poolData;
  }).reverse();
}
export function useSetLockupCallback() {
  const account = useAccount();
  const { provider } = useWeb3React();
  const addTransaction = useTransactionAdder();
  const { poolAddress: poolAddressFromUrl } = useParams();
  const poolContract = usePoolExtendedContract(poolAddressFromUrl ?? void 0);
  return useCallback(
    (lockup) => {
      if (!provider || !account.chainId || !account.address) {
        return void 0;
      }
      if (!poolContract) {
        throw new Error("No Pool Contract!");
      }
      return poolContract.estimateGas.changeMinPeriod(lockup, {}).then((estimatedGasLimit) => {
        return poolContract.changeMinPeriod(lockup, { value: null, gasLimit: calculateGasMargin(estimatedGasLimit) }).then((response) => {
          addTransaction(response, {
            type: TransactionType.SET_LOCKUP
          });
          return response.hash;
        });
      });
    },
    [account.address, account.chainId, provider, poolContract, addTransaction]
  );
}
export function useSetSpreadCallback() {
  const account = useAccount();
  const { provider } = useWeb3React();
  const addTransaction = useTransactionAdder();
  const { poolAddress: poolAddressFromUrl } = useParams();
  const poolContract = usePoolExtendedContract(poolAddressFromUrl ?? void 0);
  return useCallback(
    (spread) => {
      if (!provider || !account.chainId || !account.address) {
        return void 0;
      }
      if (!poolContract) {
        throw new Error("No Pool Contract!");
      }
      return poolContract.estimateGas.changeSpread(spread, {}).then((estimatedGasLimit) => {
        return poolContract.changeSpread(spread, { value: null, gasLimit: calculateGasMargin(estimatedGasLimit) }).then((response) => {
          addTransaction(response, {
            type: TransactionType.SET_SPREAD
          });
          return response.hash;
        });
      });
    },
    [account.address, account.chainId, provider, poolContract, addTransaction]
  );
}
export function useSetValueCallback() {
  const account = useAccount();
  const { provider } = useWeb3React();
  const addTransaction = useTransactionAdder();
  const { poolAddress: poolAddressFromUrl } = useParams();
  const poolContract = usePoolExtendedContract(poolAddressFromUrl ?? void 0);
  return useCallback(
    () => {
      if (!provider || !account.chainId || !account.address) {
        return void 0;
      }
      if (!poolContract) {
        throw new Error("No Pool Contract!");
      }
      return poolContract.estimateGas.updateUnitaryValue().then((estimatedGasLimit) => {
        return poolContract.updateUnitaryValue({ value: null, gasLimit: calculateGasMargin(estimatedGasLimit) }).then((response) => {
          addTransaction(response, {
            type: TransactionType.SET_VALUE
          });
          return response.hash;
        });
      });
    },
    [account.address, account.chainId, provider, poolContract, addTransaction]
  );
}
export function useStakingPoolsRewards(poolIds) {
  const stakingContract = useStakingContract();
  const inputs = useMemo(() => poolIds ? poolIds.map((poolId) => [poolId]) : [], [poolIds]);
  const results = useSingleContractMultipleData(stakingContract, "getStakingPoolStatsThisEpoch", inputs);
  return useMemo(() => {
    return results.map((call) => {
      const result = call.result;
      return result?.[0].feesCollected;
    });
  }, [results]);
}
export function useStakingPools(addresses, poolIds) {
  const stakingContract = useStakingContract();
  const inputs = useMemo(() => poolIds ? poolIds.map((poolId) => [poolId]) : [], [poolIds]);
  const poolAddresses = useMemo(() => addresses ? addresses.map((address) => [address, 1]) : [], [addresses]);
  const poolsData = useSingleContractMultipleData(stakingContract, "getStakingPool", inputs);
  const poolsStakes = useSingleContractMultipleData(stakingContract, "getTotalStakeDelegatedToPool", inputs);
  const poolsOwnStakes = useSingleContractMultipleData(stakingContract, "getOwnerStakeByStatus", poolAddresses);
  const poolsLoading = useMemo(() => poolsData.some(({ loading: loading2 }) => loading2), [poolsData]);
  const stakesLoading = useMemo(() => poolsStakes.some(({ loading: loading2 }) => loading2), [poolsStakes]);
  const ownStakesLoading = useMemo(() => poolsOwnStakes.some(({ loading: loading2 }) => loading2), [poolsOwnStakes]);
  const poolsError = useMemo(() => poolsData.some(({ error }) => error), [poolsData]);
  const stakesError = useMemo(() => poolsStakes.some(({ error }) => error), [poolsStakes]);
  const ownStakesError = useMemo(() => poolsOwnStakes.some(({ error }) => error), [poolsOwnStakes]);
  const stakingPools = useMemo(() => {
    if (!poolsLoading && !poolsError && poolIds) {
      return poolsData.map((call, i) => {
        const id = poolIds[i];
        const result = call.result;
        return {
          id,
          operatorShare: result?.[0].operatorShare
        };
      });
    }
    return void 0;
  }, [poolsError, poolsLoading, poolIds, poolsData]);
  const delegatedStakes = useMemo(() => {
    if (!stakesLoading && !stakesError && addresses && poolIds) {
      return poolsStakes.map((call, i) => {
        const id = poolIds[i];
        const result = call.result;
        return {
          id,
          delegatedStake: result?.[0].nextEpochBalance
        };
      });
    }
    return void 0;
  }, [stakesLoading, stakesError, addresses, poolIds, poolsStakes]);
  const delegatedOwnStakes = useMemo(() => {
    if (!ownStakesLoading && !ownStakesError && addresses && poolIds) {
      return poolsOwnStakes.map((call, i) => {
        const id = poolIds[i];
        const result = call.result;
        return {
          id,
          poolOwnStake: result?.[0].nextEpochBalance
        };
      });
    }
    return void 0;
  }, [ownStakesLoading, ownStakesError, addresses, poolIds, poolsOwnStakes]);
  const totalDelegatedStake = delegatedStakes?.reduce((prev, curr) => prev + Number(curr.delegatedStake), 0);
  const totalPoolsOwnStake = delegatedOwnStakes?.reduce((prev, curr) => prev + Number(curr.poolOwnStake), 0);
  const account = useWeb3React();
  const supplyAmount = useTotalSupply(GRG[account.chainId ?? UniverseChainId.Mainnet]);
  const yieldData = useMemo(() => {
    if (!delegatedStakes || !delegatedOwnStakes || !totalDelegatedStake || !totalPoolsOwnStake || !supplyAmount) {
      return void 0;
    }
    const poolsInfo = stakingPools?.map((pool, i) => ({
      ...pool,
      operatorShare: stakingPools[i].operatorShare,
      delegatedStake: delegatedStakes[i].delegatedStake,
      delegatedOwnStake: delegatedOwnStakes[i].poolOwnStake
    }));
    return poolsInfo?.map((p) => {
      const reward = Math.pow(p.delegatedOwnStake / totalPoolsOwnStake, 2 / 3) * Math.pow(p.delegatedStake / totalDelegatedStake, 1 / 3) * (Number(supplyAmount?.quotient) * 2 / 100);
      const apr = p.delegatedStake.toString() !== BigNumber.from(0).toString() ? reward * ((1e6 - p.operatorShare) / 1e6) / p.delegatedStake : 0;
      const irr = p.delegatedOwnStake.toString() !== BigNumber.from(0).toString() ? reward * (p.operatorShare / 1e6) / p.delegatedOwnStake : 0;
      return { apr, irr };
    });
  }, [delegatedStakes, delegatedOwnStakes, stakingPools, supplyAmount, totalDelegatedStake, totalPoolsOwnStake]);
  const loading = poolsLoading || stakesLoading || ownStakesLoading;
  return {
    loading,
    stakingPools: stakingPools?.map((pool, i) => ({
      ...pool,
      id: inputs[i][0],
      apr: yieldData ? yieldData[i]?.apr : 0,
      irr: yieldData ? yieldData[i]?.irr : 0,
      operatorShare: stakingPools[i].operatorShare,
      delegatedStake: delegatedStakes ? delegatedStakes[i].delegatedStake : 0,
      poolOwnStake: delegatedOwnStakes ? delegatedOwnStakes[i].poolOwnStake : 0
    }))
  };
}
export function useOperatedPools() {
  const { data: poolsLogs } = useAllPoolsData();
  const poolAddresses = useMemo(() => {
    if (!poolsLogs) {
      return [];
    }
    return poolsLogs.map((p) => p.pool);
  }, [poolsLogs]);
  const PoolInterface = new Interface(POOL_EXTENDED_ABI);
  const results = useMultipleContractSingleData(poolAddresses, PoolInterface, "getPool");
  const account = useAccount();
  const prevAccount = usePrevious(account.address);
  const accountChanged = prevAccount && prevAccount !== account.address;
  const operatedPools = useMemo(() => {
    if (!account.address || !account.chainId || !results || !poolAddresses) {
      return void 0;
    }
    const mockToken = new Token(0, account.address, 1);
    return results.map((result, i) => {
      const { result: pools, loading } = result;
      const poolAddress = poolAddresses[i];
      if (loading || !pools || !pools?.[0] || !poolAddress) {
        return mockToken;
      }
      const { name, symbol, decimals, owner } = pools[0];
      if (!name || !symbol || !decimals || !owner || !poolAddress) {
        return mockToken;
      }
      const isPoolOperator = owner === account.address;
      if (!isPoolOperator) {
        return mockToken;
      }
      return new Token(account.chainId ?? UniverseChainId.Mainnet, poolAddress, decimals, symbol, name);
    }).filter((p) => p !== mockToken);
  }, [account.address, account.chainId, poolAddresses, results]);
  const defaultPool = useMemo(() => operatedPools?.[0], [operatedPools]);
  const onPoolSelect = useSelectActiveSmartPool();
  useEffect(() => {
    const emptyPool = { isToken: false };
    if (accountChanged) {
      onPoolSelect(defaultPool ?? emptyPool);
    }
  }, [accountChanged, defaultPool, onPoolSelect]);
  return operatedPools;
}
