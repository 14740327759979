"use strict";
import { useQuery } from "@tanstack/react-query";
import { calculateTokensLocked } from "components/Charts/LiquidityChart";
import { usePoolActiveLiquidity } from "hooks/usePoolTickData";
import { useMemo } from "react";
export function useDensityChartData({
  poolId,
  currencyA,
  currencyB,
  feeAmount,
  invertPrices,
  version,
  chainId,
  tickSpacing,
  hooks,
  skip
}) {
  const { isLoading, error, data } = usePoolActiveLiquidity({
    currencyA,
    currencyB,
    version,
    poolId,
    feeAmount,
    chainId,
    tickSpacing,
    hooks,
    skip
  });
  const fetcher = async () => {
    if (!data?.length || !currencyA || !currencyB || !feeAmount) {
      return null;
    }
    const newData = [];
    for (let i = 0; i < data.length; i++) {
      const t = data[i];
      const price0 = invertPrices ? t.sdkPrice.invert().toSignificant(8) : t.sdkPrice.toSignificant(8);
      const { amount0Locked, amount1Locked } = await calculateTokensLocked(
        currencyA?.wrapped,
        currencyB?.wrapped,
        feeAmount,
        t
      );
      const chartEntry = {
        activeLiquidity: parseFloat(t.liquidityActive.toString()),
        price0: parseFloat(price0),
        tick: t.tick,
        amount0Locked: invertPrices ? amount0Locked : amount1Locked,
        amount1Locked: invertPrices ? amount1Locked : amount0Locked
      };
      if (chartEntry.activeLiquidity > 0) {
        newData.push(chartEntry);
      }
    }
    return newData;
  };
  const { data: formattedData } = useQuery({
    queryKey: [
      "densityChartData",
      poolId,
      currencyA,
      currencyB,
      feeAmount,
      invertPrices,
      version,
      chainId,
      tickSpacing,
      data
    ],
    queryFn: fetcher
  });
  return useMemo(() => {
    return {
      isLoading: isLoading || Boolean(data) && !formattedData,
      error,
      formattedData: isLoading ? void 0 : formattedData
    };
  }, [data, error, formattedData, isLoading]);
}
