"use strict";
import { ProtocolVersion } from "@uniswap/client-pools/dist/pools/v1/types_pb";
import { CurrencyAmount, Price, V3_CORE_FACTORY_ADDRESSES } from "@uniswap/sdk-core";
import { Pair } from "@uniswap/v2-sdk";
import {
  TickMath,
  Pool as V3Pool,
  Position as V3Position,
  encodeSqrtRatioX96,
  nearestUsableTick,
  priceToClosestTick as priceToClosestV3Tick
} from "@uniswap/v3-sdk";
import { Pool as V4Pool, Position as V4Position, priceToClosestTick as priceToClosestV4Tick } from "@uniswap/v4-sdk";
import { getProtocolItems } from "components/Liquidity/utils";
import { ZERO_ADDRESS } from "constants/misc";
import { PoolCache } from "hooks/usePools";
import JSBI from "jsbi";
import tryParseCurrencyAmount from "lib/utils/tryParseCurrencyAmount";
import { useMemo } from "react";
import { tryParsePrice, tryParseTick } from "state/mint/v3/utils";
import { PositionField } from "types/position";
import { WRAPPED_NATIVE_CURRENCY, nativeOnChain } from "uniswap/src/constants/tokens";
import {
  IndependentToken
} from "uniswap/src/data/tradingApi/__generated__";
import { UniverseChainId } from "uniswap/src/features/chains/types";
import { useCurrencyInfo, useNativeCurrencyInfo } from "uniswap/src/features/tokens/useCurrencyInfo";
import { LiquidityTransactionType } from "uniswap/src/features/transactions/liquidity/types";
import { validatePermit, validateTransactionRequest } from "uniswap/src/features/transactions/swap/utils/trade";
import { areCurrenciesEqual, buildCurrencyId } from "uniswap/src/utils/currencyId";
import { getTickToPrice, getV4TickToPrice } from "utils/getTickToPrice";
export function getSortedCurrenciesTuple(a, b) {
  if (a?.isNative || !b) {
    return [a, b];
  }
  if (b?.isNative || !a) {
    return [b, a];
  }
  return a.sortsBefore(b) ? [a, b] : [b, a];
}
export function getSortedCurrenciesTupleWithWrap(a, b, protocolVersion) {
  return getSortedCurrenciesTuple(getCurrencyWithWrap(a, protocolVersion), getCurrencyWithWrap(b, protocolVersion));
}
export function getCurrencyForProtocol(currency, protocolVersion) {
  if (!currency) {
    return void 0;
  }
  if (protocolVersion === ProtocolVersion.V4) {
    const wrappedNative = WRAPPED_NATIVE_CURRENCY[currency.chainId];
    if (areCurrenciesEqual(wrappedNative, currency)) {
      return nativeOnChain(currency.chainId);
    }
    return currency;
  }
  if (currency.isToken) {
    return currency;
  }
  return currency.wrapped;
}
export function getCurrencyWithWrap(currency, protocolVersion) {
  if (protocolVersion === ProtocolVersion.V4 || currency?.isToken) {
    return currency;
  }
  return currency?.wrapped;
}
export function getCurrencyAddressWithWrap(currency, protocolVersion) {
  if (currency?.isToken) {
    return currency.address;
  }
  if (protocolVersion === ProtocolVersion.V4) {
    return ZERO_ADDRESS;
  }
  return currency?.wrapped.address;
}
export function getCurrencyAddressForTradingApi(currency) {
  return currency?.isToken ? currency.address : ZERO_ADDRESS;
}
export function poolEnabledProtocolVersion(protocolVersion) {
  return protocolVersion === ProtocolVersion.V3 || protocolVersion === ProtocolVersion.V4;
}
export function pairEnabledProtocolVersion(protocolVersion) {
  return protocolVersion === ProtocolVersion.V2;
}
export function validateCurrencyInput(currencies) {
  return !!currencies[0] && !!currencies[1];
}
export function getPairFromPositionStateAndRangeState({
  derivedPositionInfo,
  derivedPriceRangeInfo
}) {
  if (derivedPositionInfo.creatingPoolOrPair) {
    return derivedPriceRangeInfo.protocolVersion === ProtocolVersion.V2 ? derivedPriceRangeInfo.mockPair : void 0;
  }
  return derivedPositionInfo.protocolVersion === ProtocolVersion.V2 ? derivedPositionInfo.pair : void 0;
}
export function getPoolFromPositionStateAndRangeState({
  derivedPositionInfo,
  derivedPriceRangeInfo
}) {
  if (derivedPositionInfo.protocolVersion === ProtocolVersion.V2) {
    return void 0;
  }
  if (derivedPositionInfo.creatingPoolOrPair) {
    if (derivedPositionInfo.protocolVersion === derivedPriceRangeInfo.protocolVersion && derivedPriceRangeInfo.protocolVersion === ProtocolVersion.V4) {
      return derivedPriceRangeInfo.mockPool;
    }
    if (derivedPositionInfo.protocolVersion === derivedPriceRangeInfo.protocolVersion && derivedPriceRangeInfo.protocolVersion === ProtocolVersion.V3) {
      return derivedPriceRangeInfo.mockPool;
    }
    return void 0;
  }
  if (derivedPositionInfo.protocolVersion === derivedPriceRangeInfo.protocolVersion && derivedPositionInfo.protocolVersion === ProtocolVersion.V4) {
    return derivedPositionInfo.pool;
  }
  if (derivedPositionInfo.protocolVersion === derivedPriceRangeInfo.protocolVersion && derivedPositionInfo.protocolVersion === ProtocolVersion.V3) {
    return derivedPositionInfo.pool;
  }
  return void 0;
}
export function getInvertedTuple(tuple, inverted) {
  return inverted ? [tuple[1], tuple[0]] : tuple;
}
function getPrices({
  baseCurrency,
  quoteCurrency,
  pricesAtLimit,
  pricesAtTicks,
  state
}) {
  if (!baseCurrency || !quoteCurrency) {
    return [void 0, void 0];
  }
  const lowerPrice = state.fullRange ? pricesAtLimit[0] : pricesAtTicks[0];
  const upperPrice = state.fullRange ? pricesAtLimit[1] : pricesAtTicks[1];
  return [lowerPrice, upperPrice];
}
function getInitialPrice({
  baseCurrency,
  sortedCurrencies,
  initialPrice
}) {
  const [currency0, currency1] = sortedCurrencies;
  const invertPrice = Boolean(baseCurrency && currency0 && !baseCurrency.equals(currency0));
  const parsedQuoteAmount = tryParseCurrencyAmount(initialPrice, invertPrice ? currency0 : currency1);
  if (!parsedQuoteAmount) {
    return void 0;
  }
  const baseAmount = tryParseCurrencyAmount("1", invertPrice ? currency1 : currency0);
  const price = baseAmount && parsedQuoteAmount ? new Price(baseAmount.currency, parsedQuoteAmount.currency, baseAmount.quotient, parsedQuoteAmount.quotient) : void 0;
  return invertPrice ? price?.invert() : price;
}
function getPrice(opts) {
  const { type, pool, currency0 } = opts;
  if (!pool || !currency0) {
    return void 0;
  }
  return type === ProtocolVersion.V4 ? pool.priceOf(currency0) : pool.priceOf(currency0);
}
function isInvalidPrice(price) {
  const sqrtRatioX96 = price ? encodeSqrtRatioX96(price.numerator, price.denominator) : void 0;
  return !!price && !!sqrtRatioX96 && !(JSBI.greaterThanOrEqual(sqrtRatioX96, TickMath.MIN_SQRT_RATIO) && JSBI.lessThan(sqrtRatioX96, TickMath.MAX_SQRT_RATIO));
}
function createMockV3Pool({
  baseToken,
  quoteToken,
  fee,
  price,
  invalidPrice
}) {
  if (!baseToken || !quoteToken || !fee || !price || invalidPrice) {
    return void 0;
  }
  const wrappedPrice = new Price(
    price.baseCurrency.wrapped,
    price.quoteCurrency.wrapped,
    price.denominator,
    price.numerator
  );
  const invertedPrice = wrappedPrice.baseCurrency.sortsBefore(wrappedPrice.quoteCurrency) ? wrappedPrice : wrappedPrice.invert();
  const currentTick = priceToClosestV3Tick(invertedPrice);
  const currentSqrt = TickMath.getSqrtRatioAtTick(currentTick);
  const pool = new V3Pool(baseToken, quoteToken, fee, currentSqrt, JSBI.BigInt(0), currentTick, []);
  return pool;
}
function createMockV4Pool({
  baseToken,
  quoteToken,
  fee,
  hook,
  price,
  invalidPrice
}) {
  if (!baseToken || !quoteToken || !price || invalidPrice) {
    return void 0;
  }
  const currentTick = priceToClosestV4Tick(price);
  const currentSqrt = TickMath.getSqrtRatioAtTick(currentTick);
  const pool = new V4Pool(
    baseToken,
    quoteToken,
    fee.feeAmount,
    fee.tickSpacing,
    hook ?? ZERO_ADDRESS,
    currentSqrt,
    JSBI.BigInt(0),
    currentTick
  );
  return pool;
}
function createMockPair(price) {
  if (price) {
    return new Pair(
      CurrencyAmount.fromRawAmount(price.quoteCurrency.wrapped, price.numerator),
      CurrencyAmount.fromRawAmount(price.baseCurrency.wrapped, price.denominator)
    );
  } else {
    return void 0;
  }
}
export function getDependentAmountFromV2Pair({
  independentAmount,
  otherAmount,
  pair,
  exactField,
  token0,
  token1,
  dependentToken
}) {
  const [token0Wrapped, token1Wrapped] = [token0?.wrapped, token1?.wrapped];
  if (!token0Wrapped || !token1Wrapped || !independentAmount || !pair) {
    return void 0;
  }
  try {
    const dependentTokenAmount = exactField === PositionField.TOKEN0 ? pair.priceOf(token0Wrapped).quote(independentAmount.wrapped) : pair.priceOf(token1Wrapped).quote(independentAmount.wrapped);
    return dependentToken ? dependentToken?.isNative ? CurrencyAmount.fromRawAmount(dependentToken, dependentTokenAmount.quotient) : dependentTokenAmount : void 0;
  } catch (e) {
    return otherAmount;
  }
}
export function getDependentAmountFromV3Position({
  independentAmount,
  pool,
  tickLower,
  tickUpper
}) {
  const wrappedIndependentAmount = independentAmount.wrapped;
  const independentTokenIsFirstToken = wrappedIndependentAmount.currency.equals(pool.token0);
  if (independentTokenIsFirstToken) {
    return V3Position.fromAmount0({
      pool,
      tickLower,
      tickUpper,
      amount0: wrappedIndependentAmount.quotient,
      useFullPrecision: true
    }).amount1;
  }
  return V3Position.fromAmount1({
    pool,
    tickLower,
    tickUpper,
    amount1: wrappedIndependentAmount.quotient
  }).amount0;
}
export function getDependentAmountFromV4Position({
  independentAmount,
  pool,
  tickLower,
  tickUpper
}) {
  const independentTokenIsFirstToken = independentAmount.currency.equals(pool.token0);
  if (independentTokenIsFirstToken) {
    return V4Position.fromAmount0({
      pool,
      tickLower,
      tickUpper,
      amount0: independentAmount.quotient,
      useFullPrecision: true
    }).amount1;
  }
  return V4Position.fromAmount1({
    pool,
    tickLower,
    tickUpper,
    amount1: independentAmount.quotient
  }).amount0;
}
export function getV2PriceRangeInfo({
  state,
  derivedPositionInfo
}) {
  const { currencies } = derivedPositionInfo;
  const [baseCurrency] = getInvertedTuple(currencies, state.priceInverted);
  const baseToken = getCurrencyWithWrap(baseCurrency, ProtocolVersion.V2);
  const sortedTokens = getSortedCurrenciesTuple(
    getCurrencyWithWrap(currencies[0], ProtocolVersion.V2),
    getCurrencyWithWrap(currencies[1], ProtocolVersion.V2)
  );
  const price = getInitialPrice({
    baseCurrency: baseToken,
    sortedCurrencies: sortedTokens,
    initialPrice: state.initialPrice
  });
  const invertPrice = Boolean(baseToken && sortedTokens[0] && !baseToken.equals(sortedTokens[0]));
  return {
    protocolVersion: ProtocolVersion.V2,
    price,
    mockPair: createMockPair(price),
    deposit0Disabled: false,
    deposit1Disabled: false,
    invertPrice
  };
}
export function getV3PriceRangeInfo({
  state,
  positionState,
  derivedPositionInfo
}) {
  const { fee } = positionState;
  const { protocolVersion, currencies } = derivedPositionInfo;
  const pool = derivedPositionInfo.pool;
  const tokenA = getCurrencyWithWrap(currencies[0], protocolVersion);
  const tokenB = getCurrencyWithWrap(currencies[1], protocolVersion);
  const sortedTokens = getSortedCurrenciesTuple(tokenA, tokenB);
  const [sortedToken0, sortedToken1] = sortedTokens;
  const [baseCurrency, quoteCurrency] = getInvertedTuple(currencies, state.priceInverted);
  const [baseToken, quoteToken] = [
    getCurrencyWithWrap(baseCurrency, protocolVersion),
    getCurrencyWithWrap(quoteCurrency, protocolVersion)
  ];
  const initialPriceTokens = getInvertedTuple(
    [getCurrencyWithWrap(currencies[0], protocolVersion), getCurrencyWithWrap(currencies[1], protocolVersion)],
    state.priceInverted
  );
  const price = derivedPositionInfo.creatingPoolOrPair ? getInitialPrice({
    baseCurrency: initialPriceTokens[0],
    sortedCurrencies: sortedTokens,
    initialPrice: state.initialPrice
  }) : getPrice({
    type: ProtocolVersion.V3,
    pool,
    currency0: sortedToken0
  });
  const invalidPrice = isInvalidPrice(price);
  const mockPool = createMockV3Pool({
    baseToken,
    quoteToken,
    fee: fee.feeAmount,
    price,
    invalidPrice
  });
  const poolForPosition = pool ?? mockPool;
  const tickSpaceLimits = [
    nearestUsableTick(TickMath.MIN_TICK, fee.tickSpacing),
    nearestUsableTick(TickMath.MAX_TICK, fee.tickSpacing)
  ];
  const invertPrice = Boolean(baseToken && sortedToken0 && !baseToken.equals(sortedToken0));
  const [baseRangeInput, quoteRangeInput] = invertPrice ? [state.maxPrice, state.minPrice] : [state.minPrice, state.maxPrice];
  const lowerTick = baseRangeInput === "" ? tickSpaceLimits[0] : invertPrice ? tryParseTick(sortedToken1, sortedToken0, fee.feeAmount, state.maxPrice) : tryParseTick(sortedToken0, sortedToken1, fee.feeAmount, state.minPrice);
  const upperTick = quoteRangeInput === "" ? tickSpaceLimits[1] : invertPrice ? tryParseTick(sortedToken1, sortedToken0, fee.feeAmount, state.minPrice) : tryParseTick(sortedToken0, sortedToken1, fee.feeAmount, state.maxPrice);
  const ticks = [lowerTick, upperTick];
  const invalidRange = Boolean(lowerTick !== void 0 && upperTick !== void 0 && lowerTick >= upperTick);
  const ticksAtLimit = state.fullRange ? [true, true] : [lowerTick === tickSpaceLimits[0], upperTick === tickSpaceLimits[1]];
  const pricesAtLimit = [
    getTickToPrice(sortedToken0, sortedToken1, tickSpaceLimits[0]),
    getTickToPrice(sortedToken0, sortedToken1, tickSpaceLimits[1])
  ];
  const pricesAtTicks = [
    getTickToPrice(sortedToken0, sortedToken1, ticks[0]),
    getTickToPrice(sortedToken0, sortedToken1, ticks[1])
  ];
  const isSorted = areCurrenciesEqual(baseToken, sortedToken0);
  const prices = getPrices({
    baseCurrency: baseToken,
    quoteCurrency: quoteToken,
    pricesAtLimit,
    pricesAtTicks,
    state
  });
  const outOfRange = Boolean(
    !invalidRange && price && prices[0] && prices[1] && (price.lessThan(prices[0]) || price.greaterThan(prices[1]))
  );
  const deposit0Disabled = Boolean(
    upperTick !== void 0 && poolForPosition && poolForPosition.tickCurrent >= upperTick
  );
  const deposit1Disabled = Boolean(
    lowerTick !== void 0 && poolForPosition && poolForPosition.tickCurrent <= lowerTick
  );
  const depositADisabled = invalidRange || Boolean(
    deposit0Disabled && poolForPosition && tokenA && poolForPosition.token0.equals(tokenA) || deposit1Disabled && poolForPosition && tokenA && poolForPosition.token1.equals(tokenA)
  );
  const depositBDisabled = invalidRange || Boolean(
    deposit0Disabled && poolForPosition && tokenB && poolForPosition.token0.equals(tokenB) || deposit1Disabled && poolForPosition && tokenB && poolForPosition.token1.equals(tokenB)
  );
  return {
    protocolVersion,
    ticks,
    ticksAtLimit,
    isSorted,
    price,
    prices,
    pricesAtTicks,
    pricesAtLimit,
    tickSpaceLimits,
    invertPrice,
    invalidPrice,
    invalidRange,
    outOfRange,
    deposit0Disabled: depositADisabled,
    deposit1Disabled: depositBDisabled,
    mockPool
  };
}
function tryParseV4Tick(baseToken, quoteToken, value, tickSpacing) {
  if (!baseToken || !quoteToken || !value || !tickSpacing) {
    return void 0;
  }
  const price = tryParsePrice(baseToken, quoteToken, value);
  if (!price) {
    return void 0;
  }
  let tick;
  const sqrtRatioX96 = encodeSqrtRatioX96(price.numerator, price.denominator);
  if (JSBI.greaterThanOrEqual(sqrtRatioX96, TickMath.MAX_SQRT_RATIO)) {
    tick = TickMath.MAX_TICK;
  } else if (JSBI.lessThanOrEqual(sqrtRatioX96, TickMath.MIN_SQRT_RATIO)) {
    tick = TickMath.MIN_TICK;
  } else {
    tick = priceToClosestV4Tick(price);
  }
  return nearestUsableTick(tick, tickSpacing);
}
export function getV4PriceRangeInfo({
  state,
  positionState,
  derivedPositionInfo
}) {
  const { fee, hook } = positionState;
  const { protocolVersion, currencies, pool } = derivedPositionInfo;
  const sortedCurrencies = getSortedCurrenciesTuple(currencies[0], currencies[1]);
  const [sortedCurrency0, sortedCurrency1] = sortedCurrencies;
  const [baseCurrency, quoteCurrency] = getInvertedTuple(currencies, state.priceInverted);
  const [initialPriceBaseCurrency] = getInvertedTuple(currencies, state.priceInverted);
  const price = derivedPositionInfo.creatingPoolOrPair ? getInitialPrice({
    baseCurrency: initialPriceBaseCurrency,
    sortedCurrencies,
    initialPrice: state.initialPrice
  }) : getPrice({
    type: ProtocolVersion.V4,
    pool,
    currency0: sortedCurrency0
  });
  const invalidPrice = isInvalidPrice(price);
  const mockPool = createMockV4Pool({
    baseToken: baseCurrency,
    quoteToken: quoteCurrency,
    fee,
    hook,
    price,
    invalidPrice
  });
  const poolForPosition = pool ?? mockPool;
  const tickSpaceLimits = [
    poolForPosition ? nearestUsableTick(TickMath.MIN_TICK, poolForPosition.tickSpacing) : void 0,
    poolForPosition ? nearestUsableTick(TickMath.MAX_TICK, poolForPosition.tickSpacing) : void 0
  ];
  const invertPrice = Boolean(baseCurrency && sortedCurrency0 && !baseCurrency.equals(sortedCurrency0));
  const [baseRangeInput, quoteRangeInput] = invertPrice ? [state.maxPrice, state.minPrice] : [state.minPrice, state.maxPrice];
  const lowerTick = baseRangeInput === "" ? tickSpaceLimits[0] : invertPrice ? tryParseV4Tick(sortedCurrency1, sortedCurrency0, state.maxPrice, poolForPosition?.tickSpacing) : tryParseV4Tick(sortedCurrency0, sortedCurrency1, state.minPrice, poolForPosition?.tickSpacing);
  const upperTick = quoteRangeInput === "" ? tickSpaceLimits[1] : invertPrice ? tryParseV4Tick(sortedCurrency1, sortedCurrency0, state.minPrice, poolForPosition?.tickSpacing) : tryParseV4Tick(sortedCurrency0, sortedCurrency1, state.maxPrice, poolForPosition?.tickSpacing);
  const ticks = [lowerTick, upperTick];
  const invalidRange = Boolean(lowerTick !== void 0 && upperTick !== void 0 && lowerTick >= upperTick);
  const ticksAtLimit = state.fullRange ? [true, true] : [lowerTick === tickSpaceLimits[0], upperTick === tickSpaceLimits[1]];
  const pricesAtLimit = [
    getV4TickToPrice(sortedCurrency0, sortedCurrency1, tickSpaceLimits[0]),
    getV4TickToPrice(sortedCurrency0, sortedCurrency1, tickSpaceLimits[1])
  ];
  const pricesAtTicks = [
    getV4TickToPrice(sortedCurrency0, sortedCurrency1, ticks[0]),
    getV4TickToPrice(sortedCurrency0, sortedCurrency1, ticks[1])
  ];
  const isSorted = areCurrenciesEqual(baseCurrency, sortedCurrency0);
  const prices = getPrices({
    baseCurrency,
    quoteCurrency,
    pricesAtLimit,
    pricesAtTicks,
    state
  });
  const outOfRange = Boolean(
    !invalidRange && price && prices[0] && prices[1] && (price.lessThan(prices[0]) || price.greaterThan(prices[1]))
  );
  const deposit0Disabled = Boolean(
    upperTick !== void 0 && poolForPosition && poolForPosition.tickCurrent >= upperTick
  );
  const deposit1Disabled = Boolean(
    lowerTick !== void 0 && poolForPosition && poolForPosition.tickCurrent <= lowerTick
  );
  const depositADisabled = invalidRange || Boolean(
    deposit0Disabled && poolForPosition && currencies[0] && poolForPosition.token0.equals(currencies[0]) || deposit1Disabled && poolForPosition && currencies[0] && poolForPosition.token1.equals(currencies[0])
  );
  const depositBDisabled = invalidRange || Boolean(
    deposit0Disabled && poolForPosition && currencies[1] && poolForPosition.token0.equals(currencies[1]) || deposit1Disabled && poolForPosition && currencies[1] && poolForPosition.token1.equals(currencies[1])
  );
  return {
    protocolVersion,
    ticks,
    ticksAtLimit,
    isSorted,
    price,
    prices,
    pricesAtTicks,
    pricesAtLimit,
    tickSpaceLimits,
    invertPrice,
    invalidPrice,
    invalidRange,
    outOfRange,
    deposit0Disabled: depositADisabled,
    deposit1Disabled: depositBDisabled,
    mockPool
  };
}
export function generateAddLiquidityApprovalParams({
  account,
  positionState,
  derivedPositionInfo,
  derivedDepositInfo
}) {
  const apiProtocolItems = getProtocolItems(positionState.protocolVersion);
  const currencies = derivedPositionInfo.currencies;
  const { currencyAmounts } = derivedDepositInfo;
  if (!account?.address || !apiProtocolItems || !currencyAmounts?.TOKEN0 || !currencyAmounts?.TOKEN1 || !validateCurrencyInput(currencies)) {
    return void 0;
  }
  return {
    simulateTransaction: true,
    walletAddress: account.address,
    chainId: currencyAmounts.TOKEN0.currency.chainId,
    protocol: apiProtocolItems,
    token0: getCurrencyAddressForTradingApi(currencies[0]),
    token1: getCurrencyAddressForTradingApi(currencies[1]),
    amount0: currencyAmounts?.TOKEN0?.quotient.toString(),
    amount1: currencyAmounts?.TOKEN1?.quotient.toString()
  };
}
function getIndependentToken({
  unsortedCurrencies,
  sortedToken0,
  sortedToken1,
  independentField,
  protocolVersion
}) {
  const tokenA = getCurrencyWithWrap(unsortedCurrencies[0], protocolVersion);
  const tokenB = getCurrencyWithWrap(unsortedCurrencies[1], protocolVersion);
  const token0Index = tokenA && sortedToken0.equals(tokenA) ? PositionField.TOKEN0 : PositionField.TOKEN1;
  const token1Index = tokenB && sortedToken1.equals(tokenB) ? PositionField.TOKEN1 : PositionField.TOKEN0;
  const independentToken = independentField === PositionField.TOKEN0 ? token0Index === PositionField.TOKEN0 ? IndependentToken.TOKEN_0 : IndependentToken.TOKEN_1 : token1Index === PositionField.TOKEN1 ? IndependentToken.TOKEN_1 : IndependentToken.TOKEN_0;
  return {
    independentToken,
    token0Index,
    token1Index
  };
}
export function generateCreateCalldataQueryParams({
  account,
  approvalCalldata,
  positionState,
  derivedPositionInfo,
  priceRangeState,
  derivedPriceRangeInfo,
  derivedDepositInfo,
  independentField
}) {
  const apiProtocolItems = getProtocolItems(positionState.protocolVersion);
  const currencies = derivedPositionInfo.currencies;
  const { currencyAmounts } = derivedDepositInfo;
  if (!account?.address || !apiProtocolItems || !currencyAmounts?.TOKEN0 || !currencyAmounts?.TOKEN1 || !validateCurrencyInput(currencies)) {
    return void 0;
  }
  const { token0Approval, token1Approval, positionTokenApproval, permitData } = approvalCalldata ?? {};
  if (derivedPositionInfo.protocolVersion === ProtocolVersion.V2) {
    if (derivedPositionInfo.protocolVersion !== derivedPriceRangeInfo.protocolVersion) {
      return void 0;
    }
    const pair = derivedPositionInfo.pair ?? derivedPriceRangeInfo.mockPair;
    if (!pair) {
      return void 0;
    }
    const sortedToken02 = pair.token0;
    const sortedToken12 = pair.token1;
    const { independentToken: independentToken2, token0Index: token0Index2, token1Index: token1Index2 } = getIndependentToken({
      unsortedCurrencies: currencies,
      sortedToken0: sortedToken02,
      sortedToken1: sortedToken12,
      independentField,
      protocolVersion: derivedPositionInfo.protocolVersion
    });
    const dependentField2 = independentField === PositionField.TOKEN0 ? PositionField.TOKEN1 : PositionField.TOKEN0;
    const independentAmount2 = currencyAmounts[independentField];
    const dependentAmount2 = currencyAmounts[dependentField2];
    return {
      simulateTransaction: !(permitData || token0Approval || token1Approval || positionTokenApproval),
      protocol: apiProtocolItems,
      walletAddress: account.address,
      chainId: currencyAmounts.TOKEN0.currency.chainId,
      independentAmount: independentAmount2?.quotient.toString(),
      independentToken: independentToken2,
      defaultDependentAmount: dependentAmount2?.quotient.toString(),
      position: {
        pool: {
          token0: getCurrencyAddressForTradingApi(currencyAmounts[token0Index2]?.currency),
          token1: getCurrencyAddressForTradingApi(currencyAmounts[token1Index2]?.currency)
        }
      }
    };
  }
  if (derivedPositionInfo.protocolVersion !== derivedPriceRangeInfo.protocolVersion) {
    return void 0;
  }
  const pool = derivedPositionInfo.pool ?? derivedPriceRangeInfo.mockPool;
  if (!pool) {
    return void 0;
  }
  const tickLower = priceRangeState.fullRange ? derivedPriceRangeInfo.tickSpaceLimits[0] : derivedPriceRangeInfo.ticks?.[0];
  const tickUpper = priceRangeState.fullRange ? derivedPriceRangeInfo.tickSpaceLimits[1] : derivedPriceRangeInfo.ticks?.[1];
  if (tickLower === void 0 || tickUpper === void 0) {
    return void 0;
  }
  const creatingPool = derivedPositionInfo.creatingPoolOrPair;
  const initialPrice = creatingPool ? pool.sqrtRatioX96.toString() : void 0;
  const tickSpacing = pool.tickSpacing;
  const sortedToken0 = pool.token0;
  const sortedToken1 = pool.token1;
  const { independentToken, token0Index, token1Index } = getIndependentToken({
    sortedToken0,
    sortedToken1,
    unsortedCurrencies: currencies,
    independentField,
    protocolVersion: derivedPositionInfo.protocolVersion
  });
  const dependentField = independentField === PositionField.TOKEN0 ? PositionField.TOKEN1 : PositionField.TOKEN0;
  const independentAmount = currencyAmounts[independentField];
  const dependentAmount = currencyAmounts[dependentField];
  return {
    simulateTransaction: !(permitData || token0Approval || token1Approval || positionTokenApproval),
    protocol: apiProtocolItems,
    walletAddress: account.address,
    chainId: currencyAmounts.TOKEN0.currency.chainId,
    independentAmount: independentAmount?.quotient.toString(),
    independentToken,
    initialDependentAmount: initialPrice && dependentAmount?.quotient?.toString(),
    // only set this if there is an initialPrice
    initialPrice,
    position: {
      tickLower,
      tickUpper,
      pool: {
        tickSpacing,
        token0: getCurrencyAddressForTradingApi(currencyAmounts[token0Index]?.currency),
        token1: getCurrencyAddressForTradingApi(currencyAmounts[token1Index]?.currency),
        fee: positionState.fee.feeAmount,
        hooks: positionState.hook
      }
    }
  };
}
export function generateCreatePositionTxRequest({
  approvalCalldata,
  createCalldata,
  createCalldataQueryParams,
  derivedPositionInfo,
  derivedDepositInfo
}) {
  const { currencyAmounts } = derivedDepositInfo;
  if (!createCalldata || !currencyAmounts?.TOKEN0 || !currencyAmounts?.TOKEN1) {
    return void 0;
  }
  const validatedApprove0Request = validateTransactionRequest(approvalCalldata?.token0Approval);
  if (approvalCalldata?.token0Approval && !validatedApprove0Request) {
    return void 0;
  }
  const validatedApprove1Request = validateTransactionRequest(approvalCalldata?.token1Approval);
  if (approvalCalldata?.token1Approval && !validatedApprove1Request) {
    return void 0;
  }
  const validatedRevoke0Request = validateTransactionRequest(approvalCalldata?.token0Cancel);
  if (approvalCalldata?.token0Cancel && !validatedRevoke0Request) {
    return void 0;
  }
  const validatedRevoke1Request = validateTransactionRequest(approvalCalldata?.token1Cancel);
  if (approvalCalldata?.token1Cancel && !validatedRevoke1Request) {
    return void 0;
  }
  approvalCalldata?.permitData && (approvalCalldata.permitData = void 0);
  const validatedPermitRequest = validatePermit(approvalCalldata?.permitData);
  if (approvalCalldata?.permitData && !validatedPermitRequest) {
    return void 0;
  }
  const txRequest = validateTransactionRequest(createCalldata.create);
  if (!txRequest) {
    return void 0;
  }
  const queryParams = derivedPositionInfo.protocolVersion === ProtocolVersion.V4 ? { ...createCalldataQueryParams, batchPermitData: validatedPermitRequest } : createCalldataQueryParams;
  return {
    type: LiquidityTransactionType.Create,
    unsigned: Boolean(approvalCalldata?.permitData),
    protocolVersion: derivedPositionInfo.protocolVersion,
    createPositionRequestArgs: queryParams,
    action: {
      type: LiquidityTransactionType.Create,
      currency0Amount: currencyAmounts.TOKEN0,
      currency1Amount: currencyAmounts.TOKEN1,
      liquidityToken: derivedPositionInfo.protocolVersion === ProtocolVersion.V2 ? derivedPositionInfo.pair?.liquidityToken : void 0
    },
    approveToken0Request: void 0,
    //validatedApprove0Request,
    approveToken1Request: void 0,
    //validatedApprove1Request,
    txRequest,
    approvePositionTokenRequest: void 0,
    revokeToken0Request: validatedRevoke0Request,
    revokeToken1Request: validatedRevoke1Request,
    permit: void 0
    //validatedPermitRequest,
  };
}
export function getPoolIdOrAddressFromCreatePositionInfo(positionInfo) {
  switch (positionInfo.protocolVersion) {
    case ProtocolVersion.V2:
      return positionInfo.pair?.liquidityToken.address;
    case ProtocolVersion.V3:
      return positionInfo.pool?.chainId && positionInfo.currencies[0] && positionInfo.currencies[1] ? PoolCache.getPoolAddress(
        V3_CORE_FACTORY_ADDRESSES[positionInfo.pool.chainId],
        positionInfo.currencies[0].wrapped,
        positionInfo.currencies[1].wrapped,
        positionInfo.pool.fee,
        positionInfo.pool.chainId
      ) : void 0;
    case ProtocolVersion.V4:
    default:
      return positionInfo.pool?.poolId;
  }
}
export function useCanUnwrapCurrency(currency) {
  const chainId = currency?.chainId ?? UniverseChainId.Mainnet;
  const nativeCurrencyInfo = useNativeCurrencyInfo(chainId);
  const currencyId = currency ? currency.isNative ? void 0 : buildCurrencyId(chainId, currency.address) : void 0;
  const currencyInfo = useCurrencyInfo(currencyId, { skip: !currencyId }) ?? nativeCurrencyInfo;
  if (!currency) {
    return false;
  }
  return areCurrenciesEqual(currencyInfo?.currency, nativeCurrencyInfo?.currency.wrapped);
}
export function useCurrencyInfoWithUnwrapForTradingApi({
  currency,
  shouldUnwrap
}) {
  const chainId = currency?.chainId ?? UniverseChainId.Mainnet;
  const nativeCurrencyInfo = useNativeCurrencyInfo(chainId);
  const currencyId = currency ? currency.isNative ? void 0 : buildCurrencyId(chainId, currency.address) : void 0;
  const currencyInfo = useCurrencyInfo(currencyId, { skip: !currencyId }) ?? nativeCurrencyInfo;
  const shouldUseUnwrappedCurrencyInfo = shouldUnwrap && areCurrenciesEqual(currencyInfo?.currency, nativeCurrencyInfo?.currency.wrapped);
  return useMemo(() => {
    if (!currency) {
      return void 0;
    }
    return shouldUseUnwrappedCurrencyInfo ? nativeCurrencyInfo : currencyInfo;
  }, [currency, nativeCurrencyInfo, currencyInfo, shouldUseUnwrappedCurrencyInfo]);
}
