"use strict";
import { BigNumber } from "@ethersproject/bignumber";
import { CurrencyAmount, NONFUNGIBLE_POSITION_MANAGER_ADDRESSES } from "@uniswap/sdk-core";
import { useAccount } from "hooks/useAccount";
import { useV3NFTPositionManagerContract } from "hooks/useContract";
import useBlockNumber from "lib/hooks/useBlockNumber";
import { useEffect, useState } from "react";
import { UniverseChainId } from "uniswap/src/features/chains/types";
import { unwrappedToken } from "utils/unwrappedToken";
import { assume0xAddress } from "utils/wagmi";
import { erc721Abi } from "viem";
import { useReadContract } from "wagmi";
const MAX_UINT128 = BigNumber.from(2).pow(128).sub(1);
export function useV3PositionFees(pool, tokenId, asWETH = false) {
  const positionManager = useV3NFTPositionManagerContract(false);
  const { chainId } = useAccount();
  const { data: owner } = useReadContract({
    address: assume0xAddress(NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId ?? UniverseChainId.Mainnet]),
    abi: erc721Abi,
    functionName: "ownerOf",
    args: tokenId ? [tokenId.toBigInt()] : void 0
  });
  const tokenIdHexString = tokenId?.toHexString();
  const latestBlockNumber = useBlockNumber();
  const [amounts, setAmounts] = useState();
  useEffect(() => {
    ;
    (async function getFees() {
      if (positionManager && tokenIdHexString && owner) {
        try {
          const results = await positionManager.callStatic.collect(
            {
              tokenId: tokenIdHexString,
              recipient: owner,
              // some tokens might fail if transferred to address(0)
              amount0Max: MAX_UINT128,
              amount1Max: MAX_UINT128
            },
            { from: owner }
            // need to simulate the call as the owner
          );
          setAmounts([results.amount0, results.amount1]);
        } catch {
        }
      }
    })();
  }, [positionManager, tokenIdHexString, owner, latestBlockNumber]);
  if (pool && amounts) {
    return [
      CurrencyAmount.fromRawAmount(asWETH ? pool.token0 : unwrappedToken(pool.token0), amounts[0].toString()),
      CurrencyAmount.fromRawAmount(asWETH ? pool.token1 : unwrappedToken(pool.token1), amounts[1].toString())
    ];
  } else {
    return [void 0, void 0];
  }
}
