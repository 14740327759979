"use strict";
import { useCallback } from "react";
import styled, { useTheme } from "lib/styled-components";
import { Trans } from "react-i18next";
import { flexColumnNoWrap } from "theme/styles";
import i18n from "uniswap/src/i18n";
import { ThemedText } from "theme/components/text";
import { AutoColumn } from "components/deprecated/Column";
import { RowBetween } from "components/deprecated/Row";
const InputPanel = styled.div`
  ${flexColumnNoWrap};
  position: relative;
  border-radius: 1.25rem;
  background-color: ${({ theme }) => theme.surface1};
  z-index: 1;
  width: 100%;
`;
const ContainerRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;
  border: 1px solid ${({ error, theme }) => error ? theme.critical : theme.surface3};
  transition: border-color 300ms ${({ error }) => error ? "step-end" : "step-start"},
    color 500ms ${({ error }) => error ? "step-end" : "step-start"};
  background-color: ${({ theme }) => theme.surface1};
`;
const InputContainer = styled.div`
  flex: 1;
  padding: 1rem;
`;
const Input = styled.input`
  font-size: 1.25rem;
  outline: none;
  border: none;
  flex: 1 1 auto;
  width: 0;
  background-color: ${({ theme }) => theme.surface1};
  transition: color 300ms ${({ error }) => error ? "step-end" : "step-start"};
  color: ${({ error, theme }) => error ? theme.critical : theme.neutral1};
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  width: 100%;
  ::placeholder {
    color: ${({ theme }) => theme.neutral3};
  }
  padding: 0px;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.neutral3};
  }
`;
export default function NameInputPanel({
  id,
  className = "recipient-address-input",
  label,
  placeholder,
  value,
  onChange
}) {
  const theme = useTheme();
  const handleInput = useCallback(
    (event) => {
      const input = event.target.value;
      const withoutSpaces = label === "Pool Symbol" ? input.replace(/\s+/g, "") : input;
      onChange(withoutSpaces);
    },
    [label, onChange]
  );
  const error = !label ? Boolean(value.length > 31) : label === "Pool Symbol" ? Boolean(value.length > 5) : false;
  return <InputPanel id={id}><ContainerRow error={error}><InputContainer><AutoColumn gap="md"><RowBetween><ThemedText.DeprecatedBlack color={theme.neutral2} fontWeight={500} fontSize={14}>{label ?? <Trans>Pool Name</Trans>}</ThemedText.DeprecatedBlack></RowBetween><Input
    className={className}
    type="text"
    autoComplete="off"
    autoCorrect="off"
    autoCapitalize="off"
    spellCheck="false"
    placeholder={placeholder ?? i18n.t(`max 32 characters`)}
    error={error}
    pattern="^(0x[a-fA-F0-9]{40})$"
    onChange={handleInput}
    value={value}
  /></AutoColumn></InputContainer></ContainerRow></InputPanel>;
}
