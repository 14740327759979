import { useEffect, useState } from "react";
import { breakpoints } from "ui/src/theme";
import { logger } from "utilities/src/logger/logger";
import { isExtension } from "utilities/src/platform";
const isClient = typeof window === "object";
function getDeviceDimensions() {
  return {
    fullHeight: window.innerHeight,
    fullWidth: window.innerWidth
  };
}
async function getExtensionDeviceDimensions() {
  const window2 = await chrome.windows.getCurrent();
  return {
    fullHeight: window2.height ?? 0,
    fullWidth: window2.width ?? 0
  };
}
export const useDeviceDimensions = () => {
  const [deviceDimensions, setDeviceDimensions] = useState(getDeviceDimensions);
  useEffect(() => {
    if (isExtension) {
      getExtensionDeviceDimensions().then((size) => setDeviceDimensions(size)).catch((error) => {
        logger.error("Error getting extension window size", error);
      });
    }
    async function handleResize() {
      setDeviceDimensions(isExtension ? await getExtensionDeviceDimensions() : getDeviceDimensions());
    }
    if (isClient) {
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
    return void 0;
  }, []);
  return deviceDimensions;
};
export const useIsExtraLargeScreen = () => {
  const { fullWidth } = useDeviceDimensions();
  return fullWidth >= breakpoints.xl;
};
