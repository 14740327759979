"use strict";
import { Interface } from "@ethersproject/abi";
import { CurrencyAmount } from "@uniswap/sdk-core";
import { useAccount } from "hooks/useAccount";
import { useInterfaceMulticall, useTokenContract } from "hooks/useContract";
import { useTokenBalances } from "hooks/useTokenBalances";
import JSBI from "jsbi";
import { useMultipleContractSingleData, useSingleContractMultipleData } from "lib/hooks/multicall";
import { useMemo } from "react";
import ERC20ABI from "uniswap/src/abis/erc20.json";
import { ValueType, getCurrencyAmount } from "uniswap/src/features/tokens/getCurrencyAmount";
import { isAddress } from "utilities/src/addresses";
import { nativeOnChain } from "uniswap/src/constants/tokens";
import { currencyKey } from "utils/currencyKey";
import { assume0xAddress } from "utils/wagmi";
import { useBalance } from "wagmi";
const ERC20Interface = new Interface(ERC20ABI);
const tokenBalancesGasRequirement = { gasRequired: 185e3 };
export function useCurrencyBalancesMultipleAccounts(uncheckedAddresses, currency) {
  const { chainId } = useAccount();
  const multicallContract = useInterfaceMulticall();
  const validAddressInputs = useMemo(
    () => uncheckedAddresses ? uncheckedAddresses.map(isAddress).filter((a) => a !== false).sort().map((addr) => [addr]) : [],
    [uncheckedAddresses]
  );
  const validatedToken = useMemo(
    () => !currency?.isNative && isAddress(currency?.address) && currency?.chainId === chainId ? currency : void 0,
    [chainId, currency]
  );
  const tokenContract = useTokenContract(validatedToken?.address, false);
  const balances = useSingleContractMultipleData(
    tokenContract ?? multicallContract,
    tokenContract ? "balanceOf" : "getEthBalance",
    validAddressInputs
  );
  return useMemo(
    () => validAddressInputs.reduce((memo, [address], i) => {
      const value = balances?.[i]?.result?.[0];
      if (value && chainId) {
        memo[address] = CurrencyAmount.fromRawAmount(
          validatedToken ?? nativeOnChain(chainId),
          JSBI.BigInt(value.toString())
        );
      }
      return memo;
    }, {}),
    [validAddressInputs, chainId, balances, validatedToken]
  );
}
export function useRpcTokenBalancesWithLoadingIndicator(address, tokens, skip) {
  const { chainId } = useAccount();
  const validatedTokens = useMemo(
    () => skip ? [] : tokens?.filter((t) => isAddress(t?.address) !== false && t?.chainId === chainId) ?? [],
    [chainId, tokens, skip]
  );
  const validatedTokenAddresses = useMemo(() => validatedTokens.map((vt) => vt.address), [validatedTokens]);
  const balances = useMultipleContractSingleData(
    validatedTokenAddresses,
    ERC20Interface,
    "balanceOf",
    useMemo(() => [address], [address]),
    tokenBalancesGasRequirement
  );
  const anyLoading = useMemo(() => balances.some((callState) => callState.loading), [balances]);
  return useMemo(
    () => [
      address && validatedTokens.length > 0 ? validatedTokens.reduce((memo, token, i) => {
        const value = balances?.[i]?.result?.[0];
        const amount = value ? JSBI.BigInt(value.toString()) : void 0;
        if (amount) {
          memo[token.address] = CurrencyAmount.fromRawAmount(token, amount);
        }
        return memo;
      }, {}) : {},
      anyLoading
    ],
    [address, validatedTokens, anyLoading, balances]
  );
}
function useRpcTokenBalances(address, tokens) {
  return useRpcTokenBalancesWithLoadingIndicator(address, tokens)[0];
}
function useRpcCurrencyBalances(account, currencies) {
  const tokens = useMemo(
    () => currencies?.filter((currency) => currency?.isToken ?? false) ?? [],
    [currencies]
  );
  const { chainId } = useAccount();
  const tokenBalances = useRpcTokenBalances(account, tokens);
  const containsETH = useMemo(() => currencies?.some((currency) => currency?.isNative) ?? false, [currencies]);
  const { data: nativeBalance } = useBalance({
    address: assume0xAddress(account),
    chainId,
    query: { enabled: containsETH && !!account }
  });
  return useMemo(
    () => currencies?.map((currency) => {
      if (!account || !currency || currency.chainId !== chainId) {
        return void 0;
      }
      if (currency.isToken) {
        return tokenBalances[currency.address];
      }
      if (currency.isNative && nativeBalance?.value) {
        return CurrencyAmount.fromRawAmount(currency, nativeBalance.value.toString());
      }
      return void 0;
    }) ?? [],
    [account, chainId, currencies, nativeBalance?.value, tokenBalances]
  );
}
function useGqlCurrencyBalances(account, currencies) {
  const { balanceMap } = useTokenBalances({ cacheOnly: true });
  return useMemo(() => {
    if (!account || !currencies) {
      return [];
    }
    return currencies.map((currency) => {
      if (!currency) {
        return void 0;
      }
      const key = currencyKey(currency);
      const balance = balanceMap[key];
      if (balance) {
        const currencyAmount = getCurrencyAmount({
          value: balance.balance.toString(),
          valueType: ValueType.Exact,
          currency
        });
        if (!currencyAmount) {
          return void 0;
        }
        return currencyAmount;
      } else {
        return CurrencyAmount.fromRawAmount(currency, 0);
      }
    });
  }, [account, balanceMap, currencies]);
}
export function useCurrencyBalances(account, currencies) {
  const { chainId: providerChainId } = useAccount();
  const chainId = useMemo(() => currencies?.[0]?.chainId, [currencies]);
  const isSynced = !chainId || chainId === providerChainId;
  const gqlCurrencyBalances = useGqlCurrencyBalances(account, currencies);
  const rpcCurrencyBalances = useRpcCurrencyBalances(account, currencies);
  return useMemo(() => {
    if (!account || !currencies) {
      return [];
    }
    return isSynced ? rpcCurrencyBalances : gqlCurrencyBalances;
  }, [account, currencies, isSynced, gqlCurrencyBalances, rpcCurrencyBalances]);
}
export function useTokenBalance(account, token) {
  return useCurrencyBalance(account, token);
}
export default function useCurrencyBalance(account, currency) {
  return useCurrencyBalances(
    account,
    useMemo(() => [currency], [currency])
  )[0];
}
