"use strict";
import { DropdownSelector, InternalMenuItem } from "components/DropdownSelector";
import { filterTimeAtom } from "components/Tokens/state";
import { TimePeriod } from "graphql/data/util";
import { useAtom } from "jotai";
import { useTheme } from "lib/styled-components";
import { useState } from "react";
import { Check } from "react-feather";
import { useTranslation } from "react-i18next";
import { useMedia } from "ui/src";
export var TimePeriodDisplay = /* @__PURE__ */ ((TimePeriodDisplay2) => {
  TimePeriodDisplay2["HOUR"] = "1H";
  TimePeriodDisplay2["DAY"] = "1D";
  TimePeriodDisplay2["WEEK"] = "1W";
  TimePeriodDisplay2["MONTH"] = "1M";
  TimePeriodDisplay2["YEAR"] = "1Y";
  return TimePeriodDisplay2;
})(TimePeriodDisplay || {});
export const DISPLAYS = {
  [TimePeriod.HOUR]: "1H" /* HOUR */,
  [TimePeriod.DAY]: "1D" /* DAY */,
  [TimePeriod.WEEK]: "1W" /* WEEK */,
  [TimePeriod.MONTH]: "1M" /* MONTH */,
  [TimePeriod.YEAR]: "1Y" /* YEAR */
};
export function getTimePeriodFromDisplay(display) {
  switch (display) {
    case "1H" /* HOUR */:
      return TimePeriod.HOUR;
    case "1D" /* DAY */:
      return TimePeriod.DAY;
    case "1W" /* WEEK */:
      return TimePeriod.WEEK;
    case "1M" /* MONTH */:
      return TimePeriod.MONTH;
    case "1Y" /* YEAR */:
      return TimePeriod.YEAR;
  }
}
export const ORDERED_TIMES = [
  TimePeriod.HOUR,
  TimePeriod.DAY,
  TimePeriod.WEEK,
  TimePeriod.MONTH,
  TimePeriod.YEAR
];
export default function VolumeTimeFrameSelector() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isMenuOpen, toggleMenu] = useState(false);
  const [activeTime, setTime] = useAtom(filterTimeAtom);
  const media = useMedia();
  const isLargeScreen = !media.xl;
  return <div><DropdownSelector
    isOpen={isMenuOpen}
    toggleOpen={toggleMenu}
    menuLabel={`${DISPLAYS[activeTime]} ${isLargeScreen ? t("common.volume").toLowerCase() : ""}`}
    dataTestId="time-selector"
    buttonStyle={{ height: 40 }}
    dropdownStyle={{ maxHeight: 300 }}
    adaptToSheet
    alignRight={!media.lg}
  >{ORDERED_TIMES.map((time) => <InternalMenuItem
    key={DISPLAYS[time]}
    data-testid={DISPLAYS[time]}
    onPress={() => {
      setTime(time);
      toggleMenu(false);
    }}
  ><div>{DISPLAYS[time]} {t("common.volume").toLowerCase()}</div>{time === activeTime && <Check color={theme.accent1} size={16} />}</InternalMenuItem>)}</DropdownSelector></div>;
}
